import * as React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Grid, IconButton, Typography, Paper, Chip,
  Table, TableBody, TableCell, TableContainer,
  TableHead, TablePagination, TableRow, Button,
  Dialog, DialogTitle, DialogContent, DialogActions,
  DialogContentText,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { deletePost, getPosts } from '../services/post';
import { setToast } from '../store/slices/toast';
import { POST_STATUS } from '../utils/enums';

const styles = {
  gridWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '80%',
  },
  dialogAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteBtn: {
    background: 'red',
    color: 'white',
    '&:hover': {
      background: "red",
    }
  },
  cancelBtn: {
    background: 'grey',
    color: 'white',
    '&:hover': {
      background: "grey",
    }
  },
};

const columns = [
  { id: 'title', label: 'Title', minWidth: 170 },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'category', label: 'Category', minWidth: 100 },
  { id: 'createdAt', label: 'Created at', minWidth: 100 },
];

const Posts = () => {
  const dispatch = useDispatch();
  const [posts, setPosts] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalElements, settotalElements] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectedPostId, setSelectedPostId] = React.useState(null);

  React.useEffect(() => {
    getPosts({ size: rowsPerPage, page })
      .then(({ data }) => {
        const sortedData = _.orderBy(data.content, ['createdAt'], ['desc']);
        setPosts(sortedData || []);
        setPage(data?.page);
        setRowsPerPage(data?.size);
        settotalElements(data?.totalElements);
      }).catch((error) => {
        dispatch(setToast({
          open: true,
          severity: 'error',
          message: error?.response?.data?.message || 'Something went wrong!',
        }));
      });
  }, [dispatch, rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOnDelete = async (id) => {
    try {
      const { data } = await deletePost(id);
      if (data.status === 200 && data?.payload?._id) {
        dispatch(setToast({
          open: true,
          severity: 'success',
          message: 'Post deleted successfully!',
        }));
        const { data } = await getPosts({ size: rowsPerPage, page });
        const sortedData = _.orderBy(data.content, ['createdAt'], ['desc']);
        if (sortedData) {
          setPosts(sortedData || []);
          setPage(data?.page);
          setRowsPerPage(data?.size);
          setOpen(false);
        }
      }
    } catch (error) {
      dispatch(setToast({
        open: true,
        severity: 'error',
        message: error?.response?.data?.message || 'Something went wrong!',
      }));
    }
  };

  const handleDeleteModal = (id) => {
    setOpen(true);
    setSelectedPostId(id);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid container sx={styles.gridWrapper}>
        <Typography variant="h4" component="h1" gutterBottom fontWeight={700}>Stories</Typography>
        <Link to="/new-story">
          <Button variant='outlined'>New Story</Button>
        </Link>
      </Grid>
      <Grid container sx={styles.gridWrapper}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: 700 }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {posts.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No posts found!
                    </TableCell>
                  </TableRow>
                )}
                {posts.map((row, idx) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={`row-${idx}`}>
                      <TableCell>
                        {row.title}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={row.status}
                          sx={{
                            color: 'white',
                            background: row.status === POST_STATUS.PUBLISHED ? 'green' : 'grey',
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {row?.categories?.map((category, idx) => (
                          <Chip
                            key={idx}
                            label={category}
                            sx={{ mb: 1 }}
                          />
                        ))}
                      </TableCell>
                      <TableCell>
                        {new Date(row.createdAt).toLocaleString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </TableCell>
                      <TableCell sx={{ p: 0 }}>
                        <Link to={`/post/${row._id}`}>
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Link>
                        <IconButton onClick={() => handleDeleteModal(row._id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
      >
        <Grid item padding={3}>
          <DialogTitle textAlign='center' fontWeight={800}>Delete Post</DialogTitle>
          <DialogContent>
            <DialogContentText textAlign='center'>
              Are you sure you want to delete this post?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={styles.dialogAction}>
            <Button
              sx={styles.cancelBtn}
              onClick={handleClose}
              variant='contained'
            >
              Cancel
            </Button>
            <Button
              sx={styles.deleteBtn}
              onClick={() => handleOnDelete(selectedPostId)}
              variant='contained'
            >
              Delete
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </>

  );
}

export default Posts;
