import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUsers } from "../../services/user";

const initialState = {
  allUsers: [],
  loading: false,
  loaded: false,
  error: "",
  size: 10,
  page: 0,
  totalElements: 0,
};

export const fetchAllUsers = createAsyncThunk(
  "user/fetchAllUsers",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await getUsers(payload);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const GET_ALL_USERS_PENDING = fetchAllUsers.pending;
const GET_ALL_USERS_FULFILLED = fetchAllUsers.fulfilled;
const GET_ALL_USERS_REJECTED = fetchAllUsers.rejected;

const allUsers = createSlice({
  name: "allUsers",
  initialState,
  reducers: {
    setSize: (state, { payload }) => {
      state.size = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GET_ALL_USERS_PENDING, (state) => {
      state.loading = true;
    });
    builder.addCase(GET_ALL_USERS_FULFILLED, (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.allUsers = payload.content;
      state.error = "";
      state.size = payload.size;
      state.page = payload.page;
      state.totalElements = payload.totalElements;
    });
    builder.addCase(GET_ALL_USERS_REJECTED, (state, { payload }) => {
      state.loading = false;
      state.loaded = false;
      // eslint-disable-next-line no-self-assign
      state.allUsers = state.allUsers;
      state.error = payload.message;
      state.size = payload.size;
      state.page = payload.page;
      state.totalElements = payload.totalElements;
    });
  },
});
export const { setPage, setSize } = allUsers.actions;
export default allUsers.reducer;
