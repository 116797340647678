import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const ITEM_HEIGHT = 48;

const options = [
  'Profile',
  'Sign out',
];

const styles = {
  profileIcon: {
    width: 36,
    cursor: 'pointer',
  },
};

const ProfileMenuList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionButtons = (option) => {
    if (option === 'Profile') {
      navigate('/profile');
      setAnchorEl(null);
    } else if (option === 'Sign out') {
      sessionStorage.removeItem("token");
      dispatch({ type: "logout/LOGOUT" });
      navigate("/login", { replace: true });
    }
  };

  return (
    <div>
      <img
        style={styles.profileIcon}
        loading="lazy"
        src="https://topafrobeats.s3.amazonaws.com/assets/img/user_default.png"
        onClick={handleClick}
        alt="user"
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={() => handleActionButtons(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ProfileMenuList;
