import Layout from "../components/Layout";
import ProtectedRoute from "../components/ProtectedRoute";
import Home from "../pages/Home";
import Login from "../pages/Login";
import AddPost from "../pages/AddPost";
import Post from "../pages/Post";
import Page404 from "../pages/404";
import Profile from "../pages/Profile";
import { Navigate } from "react-router-dom";

const GetRoleBasedRoutes = (role) => {
  const adminRoutes = [
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <ProtectedRoute component={Home} />,
        },
        {
          path: "new-story",
          element: <ProtectedRoute component={AddPost} />,
        },
        {
          path: "post/:id",
          element: <ProtectedRoute component={Post} />,
        },
        {
          path: "profile",
          element: <ProtectedRoute component={Profile} />,
        },
      ],
    },
    { path: "*", element: <Page404 /> },
    { path: "login", element: <Login /> },
  ];

  const editorRoutes = [
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <ProtectedRoute component={Home} />,
        },
        {
          path: "new-story",
          element: <ProtectedRoute component={AddPost} />,
        },
        {
          path: "post/:id",
          element: <ProtectedRoute component={Post} />,
        },
        {
          path: "profile",
          element: <ProtectedRoute component={Profile} />,
        },
      ],
    },
    { path: "*", element: <Page404 /> },
    { path: "login", element: <Login /> },
  ];

  const unAuthRoutes = [
    { path: "login", element: <Login /> },
    { path: "/", element: <Navigate to="login" /> },
  ];

  const ROLES_ROUTES = {
    ADMIN: adminRoutes,
    EDITOR: editorRoutes,
    undefined: unAuthRoutes,
  };

  return ROLES_ROUTES[role] ?? unAuthRoutes;
};

export default GetRoleBasedRoutes;
