import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button, Chip, CircularProgress, Grid, Input, Switch,
  TextField, Typography,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Editor from "../components/Editor";
import { createPost, uploadImage } from "../services/post";
import { setToast } from "../store/slices/toast";
import {
  isReadyToSubmitPostTitle,
  isReadyToSubmitPostSlug,
} from "../utils/HelperFunction";
import { POST_STATUS } from "../utils/enums";

const styles = {
  coverImageWrapper: {
    position: 'relative',
    border: '1px dashed #ccc',
  },
  coverImageInput: {
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    cursor: 'pointer',
  },
  coverImage: {
    width: '100%',
  },
  coverImageLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
  tags: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: 5,
  },
  categories: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: 5,
  },
  title: {
    mb: 1,
    fontSize: 32,
    width: "100%",
  },
};

const defaultCoverImage = "https://topafrobeats.s3.amazonaws.com/assets/img/placeholder-image.webp";

const AddPost = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [coverImage, setCoverImage] = React.useState(defaultCoverImage);
  const [isCoverImageSubmit, setIsCoverImageSubmit] = React.useState(false);
  const [featured, setFeatured] = React.useState(false);
  const [staffPicks, setStaffPicks] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  const handleOnDraft = async (ejData) => {
    try {
      if (!isReadyToSubmitPostTitle(title)) {
        dispatch(setToast({
          open: true,
          severity: 'error',
          message: 'Please add a title!',
        }));
        return;
      }
      if (!isReadyToSubmitPostSlug(slug)) {
        dispatch(setToast({
          open: true,
          severity: 'error',
          message: 'Please add a Slug!',
        }));
        return;
      }
      const ejContent = await ejData.saver.save();
      const { data } = await createPost({
        title,
        blocks: ejContent?.blocks || [],
        coverImage,
        status: POST_STATUS.DRAFT,
        slug,
        featured,
        staffPicks,
        tags,
        categories,
      });
      if (data) {
        dispatch(setToast({
          open: true,
          severity: 'success',
          message: 'Draft saved successfully!',
        }));
      }
    } catch (error) {
      dispatch(setToast({
        open: true,
        severity: 'error',
        message: error?.response?.data?.message || 'Something went wrong!',
      }));
    }
  };

  const handleOnPublish = async (ejData) => {
    try {
      if (!isReadyToSubmitPostTitle(title)) {
        dispatch(setToast({
          open: true,
          severity: 'error',
          message: 'Please add a title!',
        }));
        return;
      }
      if (!isReadyToSubmitPostSlug(slug)) {
        dispatch(setToast({
          open: true,
          severity: 'error',
          message: 'Please add a Slug!',
        }));
        return;
      }
      const ejContent = await ejData.saver.save();
      const { data } = await createPost({
        title,
        blocks: ejContent?.blocks || [],
        coverImage,
        status: POST_STATUS.PUBLISHED,
        slug,
        featured,
        staffPicks,
        tags,
        categories,
      });
      if (data) {
        dispatch(setToast({
          open: true,
          severity: 'success',
          message: 'Post published successfully!',
        }));
      }
    } catch (error) {
      dispatch(setToast({
        open: true,
        severity: 'error',
        message: error?.response?.data?.message || 'Something went wrong!',
      }));
    }
  };

  const handleOnChangeImage = async (e) => {
    try {
      setIsCoverImageSubmit(true);
      const file = e.target.files[0];
      const { data } = await uploadImage(file);
      if (data) {
        setIsCoverImageSubmit(false);
        setCoverImage(data.payload);
        dispatch(setToast({
          open: true,
          severity: 'success',
          message: 'Image uploaded successfully!',
        }));
      }
    } catch (error) {
      setIsCoverImageSubmit(false);
      dispatch(setToast({
        open: true,
        severity: 'error',
        message: error?.response?.data?.message || 'Something went wrong!',
      }));
    }
  };

  const handleOnKeyDownTags = (e) => {
    if (e.key === 'Enter') {
      setTags([...tags, e.target.value]);
      e.target.value = '';
    }
  };

  const handleOnKeyDownCategories = (e) => {
    if (e.key === 'Enter') {
      setCategories([...categories, e.target.value]);
      e.target.value = '';
    }
  };

  return (
    <Grid container width='100%' spacing={3}>
      <Grid item xs={12}>
        <Link to="/">
          <Button variant='text' startIcon={<ArrowBackIcon />}>Back</Button>
        </Link>
      </Grid>
      <Grid item xs={9}>
        <Input
          sx={styles.title}
          type="text"
          className="post-title"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Grid item xs={12} mt={1} mb={4} gap={1} display="flex" alignItems="center" justifyContent="start">
          <Typography variant="body2" fontWeight={600}>Slug:</Typography>
          <Input
            fullWidth
            type="text"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
          />
        </Grid>
        <Editor
          type="add"
          handleOnDraft={handleOnDraft}
          handleOnPublish={handleOnPublish}
        />
      </Grid>
      <Grid item xs={3}>
        {/* COVER IMAGE */}
        <Grid mb={3}>
          <Typography variant="body2" mb={1} fontWeight={600} color='GrayText'>Add Cover Image</Typography>
          <Grid style={styles.coverImageWrapper}>
            <input
              style={styles.coverImageInput}
              type="file"
              accept="image/*"
              onChange={handleOnChangeImage}
            />
            <img
              style={styles.coverImage}
              src={coverImage}
              alt="cover_image"
            />
            {isCoverImageSubmit && (
              <Box sx={styles.coverImageLoading}>
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Grid>
        {/* FEATURED POST SWITCH */}
        <Grid display="flex" gap={3} mb={3}>
          <Typography
            variant="body2"
            fontWeight={600}
            color='GrayText'
          >
            Featured Post:
          </Typography>
          <Switch
            color="primary"
            checked={featured}
            onChange={(e) => setFeatured(e.target.checked)}
          />
        </Grid>
        {/* STAFF PICK SWITCH */}
        <Grid display="flex" gap={3} mb={3}>
          <Typography
            variant="body2"
            fontWeight={600}
            color='GrayText'
          >
            Staff Pick:
          </Typography>
          <Switch
            color="primary"
            checked={staffPicks}
            onChange={(e) => setStaffPicks(e.target.checked)}
          />
        </Grid>
        {/* CATEGORIES */}
        <Grid mb={3}>
          <Typography variant="body2" mb={1} fontWeight={600} color='GrayText'>Add Category</Typography>
          <TextField
            hiddenLabel
            variant="outlined"
            sx={{ mb: 3 }}
            onKeyDown={handleOnKeyDownCategories}
            disabled={categories.length > 0}
          />
          <Grid style={styles.categories}>
            {categories.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => setCategories(categories.filter((_, i) => i !== index))}
                sx={{ mb: 1 }}
              />
            ))}
          </Grid>
        </Grid>
        {/* TAGS */}
        <Grid>
          <Typography variant="body2" mb={1} fontWeight={600} color='GrayText'>Add Tags</Typography>
          <TextField
            hiddenLabel
            variant="outlined"
            sx={{ mb: 3 }}
            onKeyDown={handleOnKeyDownTags}
          />
          <Grid style={styles.tags}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => setTags(tags.filter((_, i) => i !== index))}
                sx={{ mb: 1 }}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddPost;
