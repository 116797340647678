import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editor: {
    blocks: [],
  },
};

const editor = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setEditorContent: (state, { payload }) => {
      state.editor.blocks = payload;
    },
  },
});


export const { setEditorContent } = editor.actions;
export default editor.reducer;
