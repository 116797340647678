import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button, Dialog, DialogActions, Grid,
  DialogContent, DialogTitle, DialogContentText,
} from "@mui/material";
import EditorJS from "@editorjs/editorjs";
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import Quote from '@editorjs/quote';
import Delimiter from '@editorjs/delimiter';
import List from "@editorjs/list";
import AnyButton from 'editorjs-button';
import DragDrop from 'editorjs-drag-drop';
// import ColorPlugin from 'editorjs-text-color-plugin';
import ImageTool from '@editorjs/image';
import Embed from '@editorjs/embed';
import { setToast } from "../store/slices/toast";
import { uploadImage } from "../services/post";

const styles = {
  actionButtons: {
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 100,
    width: "100%",
    background: "#fff",
    px: 10,
    py: 1.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: 1,
  },
  draftBtn: {
    background: "#8b8b8b",
    border: "1px solid #8b8b8b",
    borderRadius: 5,
    color: "#fff",
    fontSize: 10,
    minWidth: 100,
    '&:hover': {
      background: "#8b8b8b",
    }
  },
  publishBtn: {
    background: "#1a8917",
    border: "1px solid #1a8917",
    borderRadius: 5,
    color: "#fff",
    fontSize: 10,
    minWidth: 100,
    '&:hover': {
      background: "#1a8917",
    }
  },
  dialogAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  publishConfirmBtn: {
    background: "#1a8917",
    color: "#fff",
    '&:hover': {
      background: "#1a8917",
    }
  },
  cancelBtn: {
    background: 'grey',
    color: '#fff',
    '&:hover': {
      background: "grey",
    }
  },
};

const Editor = ({ type, handleOnDraft, handleOnPublish }) => {
  const dispatch = useDispatch();
  const ejInstanceRef = React.useRef(null);
  const { editor } = useSelector((state) => state.editor);
  const [open, setOpen] = React.useState(false);

  const initEditor = () => {
    const ejData = new EditorJS({
      holder: 'editorjs',
      onReady: () => {
        ejInstanceRef.current = ejData;
        new DragDrop(ejData);
      },
      autofocus: false,
      data: type === "edit" ? editor : { blocks: [] },
      placeholder: "Let's write an awesome story!",
      tools: {
        paragraph: {
          class: Paragraph,
          inlineToolbar: false,
          tunes: ["anyTuneName"],
        },
        header: {
          class: Header,
          inlineToolbar: false,
          config: {
            placeholder: 'Enter a header',
            levels: [1, 2, 3, 4, 5, 6],
            defaultLevel: 2,
            defaultAlignment: 'left',
          },
          tunes: ["anyTuneName"],
        },
        anyTuneName: {
          class: AlignmentTuneTool,
          config: {
            default: "left",
            blocks: {
              header: 'left',
              list: 'left'
            }
          },
        },
        quote: {
          class: Quote,
          inlineToolbar: false,
          config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: 'Quote\'s author',
          },
        },
        list: {
          class: List,
          inlineToolbar: false,
          config: {
            defaultStyle: 'unordered'
          }
        },
        AnyButton: {
          class: AnyButton,
          inlineToolbar: false,
          config: {
            css: {
              "btnColor": "btn--gray",
            }
          }
        },
        // Color: {
        //   class: ColorPlugin,
        //   config: {
        //     colorCollections: ['#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
        //     defaultColor: '#000000',
        //     type: 'text',
        //     customPicker: true,
        //   },
        // },
        image: {
          class: ImageTool,
          config: {
            additionalRequestHeaders: {
              "authorization": `Bearer ${sessionStorage.getItem("token")}`,
            },
            uploader: {
              async uploadByFile(file) {
                return uploadImage(file)
                  .then(({ data }) => {
                    dispatch(setToast({
                      open: true,
                      severity: 'success',
                      message: 'Image uploaded successfully!',
                    }));
                    return {
                      success: 1,
                      file: {
                        url: data.payload,
                      }
                    };
                  }).catch(() => {
                    dispatch(setToast({
                      open: true,
                      severity: 'error',
                      message: 'Image upload failed! Please try again.',
                    }));
                    return {
                      success: 0,
                      file: {
                        url: '',
                      }
                    };
                  });
              },
            },
          }
        },
        embed: {
          class: Embed,
          inlineToolbar: true,
          config: {
            services: {
              youtube: true,
              twitter: true,
              facebook: true,
              instagram: true,
            }
          }
        },
        delimiter: Delimiter,
      },
    });
  };

  React.useEffect(() => {
    if (ejInstanceRef.current === null) {
      if ((type === "edit" && editor.blocks.length >= 0) || type === "add") initEditor();
    }
    return () => {
      ejInstanceRef?.current?.destroy();
      ejInstanceRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, type]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnPublishConfirmation = () => {
    handleOnPublish(ejInstanceRef.current);
    setOpen(false);
  };

  return (
    <>
      <div id='editorjs' />
      <Grid item xs={12} sx={styles.actionButtons}>
        <Button
          sx={styles.draftBtn}
          variant="contained"
          onClick={() => handleOnDraft(ejInstanceRef.current)}
        >
          Save Draft
        </Button>
        <Button
          sx={styles.publishBtn}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Publish
        </Button>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
      >
        <Grid item padding={3}>
          <DialogTitle textAlign='center' fontWeight={800}>Publish Post</DialogTitle>
          <DialogContent>
            <DialogContentText textAlign='center'>
              Are you sure you want to publish this post?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={styles.dialogAction}>
            <Button
              sx={styles.cancelBtn}
              onClick={handleClose}
              variant='contained'
            >
              Cancel
            </Button>
            <Button
              sx={styles.publishConfirmBtn}
              onClick={() => handleOnPublishConfirmation(ejInstanceRef.current)}
              variant='contained'
            >
              Publish
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  );
}

export default Editor;