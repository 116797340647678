import React from 'react';
import Posts from './Posts';

const Home = () => {
  return (
    <Posts />
  );
};

export default Home;
