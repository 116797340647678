import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Divider, Grid, IconButton, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TablePagination,
  TableRow, TextField, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import { addUser, deleteUser, updatePassword } from '../services/user';
import { setToast } from '../store/slices/toast';
import { fetchAllUsers, setPage, setSize } from '../store/slices/allusers';
import { ROLES } from '../utils/enums';

const styles = {
  updateProfileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    rowGap: 2,
    mt: 5,
  },
  gridWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteBtn: {
    background: 'red',
    color: 'white',
    '&:hover': {
      background: "red",
    }
  },
  cancelBtn: {
    background: 'grey',
    color: 'white',
    '&:hover': {
      background: "grey",
    }
  },
  paper: {
    width: '100%',
    overflow: 'hidden',
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: 'black',
  },
};

const columns = [
  { id: 'fullName', label: 'Full Name', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 100 },
  { id: 'role', label: 'Role', minWidth: 100 },
  { id: 'createdAt', label: 'Created At', minWidth: 100 },
];

const Profile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { allUsers, size, page, totalElements } = useSelector((state) => state.allUsers);
  const [profile, setProfile] = React.useState({
    fullName: '',
    email: '',
    password: '',
  });
  const [newUser, setNewUser] = React.useState({
    fullName: '',
    email: '',
    password: '',
  });
  const [modal, setModal] = React.useState({
    open: false,
    type: '',
  });
  const [selectedUserId, setSelectedUserId] = React.useState(null);

  React.useEffect(() => {
    if (user.role === ROLES.ADMIN)
      dispatch(fetchAllUsers({ size, page }));
  }, [dispatch, size, page, user.role]);

  React.useEffect(() => {
    if (user) {
      setProfile((prev) => ({
        ...prev,
        fullName: user.fullName,
        email: user.email,
      }));
    }
  }, [user]);

  const handleOnChange = (e) => {
    setProfile((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnChangeAddUser = (e) => {
    setNewUser((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(Number(newPage)));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setSize(Number(event.target.value)));
    dispatch(setPage(0));
  };

  const handleDeleteModal = (id) => {
    setModal({ open: true, type: 'delete' });
    setSelectedUserId(id);
  };

  const handleClose = () => setModal({ open: false, type: '' });

  const handleOnUpdateUser = async (e) => {
    e.preventDefault();
    try {
      const { data } = await updatePassword(profile);
      if (data) {
        setProfile((prev) => ({
          ...prev,
          password: '',
        }));
        dispatch(setToast({
          open: true,
          severity: 'success',
          message: 'Profile info updated successfully!',
        }));
      }
    } catch (error) {
      dispatch(setToast({
        open: true,
        severity: 'error',
        message: error?.response?.data?.message || 'Something went wrong!',
      }));
    }
  };

  const handleOnAddUser = async (e) => {
    e.preventDefault();
    try {
      const { data } = await addUser(newUser);
      if (data) {
        setNewUser({
          fullName: '',
          email: '',
          password: '',
        });
        dispatch(setToast({
          open: true,
          severity: 'success',
          message: 'User added successfully!',
        }));
        dispatch(fetchAllUsers({ size, page }));
        setModal({ open: false, type: '' });
      }
    } catch (error) {
      dispatch(setToast({
        open: true,
        severity: 'error',
        message: error?.response?.data?.message || 'Something went wrong!',
      }));
    }
  };

  const handleOnDelete = async (id) => {
    try {
      const { data } = await deleteUser(id);
      if (data.status === 200) {
        dispatch(setToast({
          open: true,
          severity: 'success',
          message: 'User deleted successfully!',
        }));
        dispatch(fetchAllUsers({ size, page }));
        setModal({ open: false, type: '' });
      }
    } catch (error) {
      dispatch(setToast({
        open: true,
        severity: 'error',
        message: error?.response?.data?.message || 'Something went wrong!',
      }));
    }
  };

  return (
    <>
      {/* UPDATE ADMIN PROFILE INFO */}
      <Grid container>
        <Link to="/">
          <Button variant='text' startIcon={<ArrowBackIcon />}>Back</Button>
        </Link>
        <Grid item xs={12} sx={styles.updateProfileWrapper}>
          <Typography variant="h4" component="h1" gutterBottom fontWeight={700}>Update Profile</Typography>
          <TextField
            type="text"
            name="fullName"
            label="Full Name"
            variant="outlined"
            value={profile.fullName}
            onChange={handleOnChange}
            sx={{ width: '40%' }}
          />
          <TextField
            type="email"
            name="email"
            label="Email"
            variant="outlined"
            value={profile.email}
            onChange={handleOnChange}
            sx={{ width: '40%' }}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            variant="outlined"
            value={profile.password}
            onChange={handleOnChange}
            sx={{ width: '40%' }}
          />
          <Button
            sx={{ width: '40%' }}
            variant='contained'
            color='primary'
            onClick={handleOnUpdateUser}
          >
            Update
          </Button>
        </Grid>
      </Grid>
      {/* USERS LIST */}
      {user.role === ROLES.ADMIN && (
        <>
          <Box width="100%" my={5}>
            <Divider light />
          </Box>
          <Grid container sx={styles.gridWrapper}>
            <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" component="h1" gutterBottom fontWeight={700}>Users</Typography>
              <Button
                variant='outlined'
                onClick={() => setModal({ open: true, type: 'add' })}
              >
                Add New User
              </Button>
            </Grid>
            <Paper sx={styles.paper}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, fontWeight: 700 }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allUsers.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No users found!
                        </TableCell>
                      </TableRow>
                    )}
                    {allUsers?.map((row, idx) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={`row-${idx}`}>
                          <TableCell>
                            {row.fullName}
                          </TableCell>
                          <TableCell>
                            {row.email}
                          </TableCell>
                          <TableCell>
                            {row.role}
                          </TableCell>
                          <TableCell>
                            {new Date(row.createdAt).toLocaleString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            })}
                          </TableCell>
                          <TableCell sx={{ p: 0 }}>
                            <IconButton onClick={() => handleDeleteModal(row._id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalElements}
                rowsPerPage={size}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
          <Dialog
            open={modal.open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
          >
            {/* ADD USER */}
            {modal.type === 'add' && (
              <Grid item xs={12}>
                <Button
                  variant='text'
                  endIcon={<CloseIcon />}
                  sx={styles.closeBtn}
                  onClick={handleClose}
                />
                <DialogTitle textAlign='center' fontWeight={800}>Add User</DialogTitle>
                <DialogContent>
                  <Grid
                    item
                    xs={12}
                    display='flex'
                    alignItems="center"
                    justifyContent="center"
                    flexDirection='column'
                    rowGap={2}
                    p={3}
                  >
                    <TextField
                      type="text"
                      name="fullName"
                      label="Full Name"
                      variant="outlined"
                      value={newUser.fullName}
                      onChange={handleOnChangeAddUser}
                      fullWidth
                    />
                    <TextField
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      value={newUser.email}
                      onChange={handleOnChangeAddUser}
                      fullWidth
                    />
                    <TextField
                      type="password"
                      name="password"
                      label="Password"
                      variant="outlined"
                      value={newUser.password}
                      onChange={handleOnChangeAddUser}
                      fullWidth
                    />
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={handleOnAddUser}
                    >
                      Add
                    </Button>
                  </Grid>
                </DialogContent>
              </Grid>
            )}
            {/* DELETE MODAL */}
            {modal.type === 'delete' && (
              <Grid item padding={3}>
                <DialogTitle textAlign='center' fontWeight={800}>Delete User</DialogTitle>
                <DialogContent>
                  <DialogContentText textAlign='center'>
                    Are you sure you want to delete this user?
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={styles.dialogAction}>
                  <Button
                    sx={styles.cancelBtn}
                    onClick={handleClose}
                    variant='contained'
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={styles.deleteBtn}
                    onClick={() => handleOnDelete(selectedUserId)}
                    variant='contained'
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Grid>
            )}
          </Dialog>
        </>
      )}
    </>
  );
};

export default Profile;
