import React from 'react';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import Header from './Header';

const styles = {
  gridWrapper: {
    px: 10,
    pt: 15,
    pb: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
};

const Layout = () => {
  return (
    <>
      <Header />
      <Grid sx={styles.gridWrapper}>
        <Outlet />
      </Grid>
    </>
  );
};

export default Layout;
