import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useRoutes } from "react-router-dom";
import Loader from "./components/Loader";
import { loadGetUserInfo } from "./store/slices/user";
import GetRoleBasedRoutes from "./utils/Routes";
import { Alert, Snackbar } from "@mui/material";
import { setToast } from "./store/slices/toast";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const toast = useSelector((state) => state.toast);

  const routes = GetRoleBasedRoutes(user.role);
  const element = useRoutes(routes);

  React.useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      dispatch(loadGetUserInfo())
        .catch(() => {
          navigate("/login", { replace: true });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleClose = () => {
    dispatch(setToast({ open: false, severity: 'info', message: '' }));
  };

  return (
    <>
      <Suspense fallback={<Loader text="page" />}>{element}</Suspense>
      <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toast.severity} sx={{ width: '100%' }}>
          {toast.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default App;
