import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loadLoginUser } from '../store/slices/user';
import { setToast } from '../store/slices/toast';
import { isValidEmail } from '../utils/HelperFunction';

const styles = {
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
  },
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, user } = useSelector((state) => state.user);

  const [login, setLogin] = React.useState({
    email: '',
    password: '',
  });
  const [isSubmit, setIsSubmit] = React.useState(false);

  React.useEffect(() => {
    if (user && user?._id) navigate("/", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    if (error) {
      dispatch(setToast({
        open: true,
        severity: 'error',
        message: error,
      }));
    }
  }, [dispatch, error]);

  const handleOnChange = (e) => {
    setLogin((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    dispatch(loadLoginUser(login))
      .then(() => {
        setIsSubmit(false);
        navigate("/", { replace: true });
      }).catch(() => {
        setIsSubmit(false);
      });
  };

  return (
    <Grid container height='100vh'>
      <Grid item xs={6}>
        <img src="https://topafrobeats.s3.amazonaws.com/assets/img/intro_logo.webp" alt="login" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Grid>
      <Grid item xs={6} sx={styles.wrapper}>
        <Typography variant='h5' fontWeight={600}>Welcome to</Typography>
        <Typography variant='h5' fontWeight={600} mb={2}>Top Afrobeats CMS</Typography>
        <TextField
          type="email"
          name="email"
          label="Email"
          variant="outlined"
          value={login.email}
          onChange={handleOnChange}
          onKeyDown={(e) => e.key === 'Enter' && handleOnSubmit(e)}
          sx={{ width: '40%' }}
        />
        <TextField
          type="password"
          name="password"
          label="Password"
          variant="outlined"
          value={login.password}
          onChange={handleOnChange}
          onKeyDown={(e) => e.key === 'Enter' && handleOnSubmit(e)}
          sx={{ width: '40%' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnSubmit}
          disabled={!isValidEmail(login.email) || !login.password || isSubmit}
          sx={{ width: '40%' }}
          endIcon={isSubmit && <CircularProgress size={20} />}
        >
          Login
        </Button>
      </Grid>
    </Grid >
  );
};

export default Login;
