import httpBase from "../services/httpBase";

export const getUsers = async (payload) => {
  return await httpBase.get(`user/list?size=${payload.size}&page=${payload.page}`);
};

export const getUser = async () => {
  return await httpBase.get("user/info");
};

export const loginUser = async (user) => {
  return await httpBase.post("user/login", user);
};

export const updatePassword = async (payload) => {
  return await httpBase.put("user/update_password", payload);
};

export const deleteUser = async (id) => {
  return await httpBase.delete(`user/${id}`);
};

export const addUser = async (payload) => {
  return await httpBase.post('user/register_editors', payload);
};

