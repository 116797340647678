import httpBase from "./httpBase";

export const getPosts = async (payload) => {
  return await httpBase.get(`post/list?size=${payload.size}&page=${payload.page}`);
};

export const getPostById = async (id) => {
  return await httpBase.get(`post/${id}`);
};

export const updatePost = async (id, payload) => {
  return await httpBase.put(`post/${id}`, { ...payload });
};

export const deletePost = async (id) => {
  return await httpBase.delete(`post/${id}`);
};

export const createPost = async (payload) => {
  return await httpBase.post("post/create", payload);
};

export const uploadImage = async (payload) => {
  const formData = new FormData();
  formData.append("file", payload);
  return await httpBase.post("post/upload/image", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
