import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from '../store/slices/user';
import editorReducer from '../store/slices/editor';
import toastReducer from '../store/slices/toast';
import allUsersReducer from '../store/slices/allusers';

const appReducer = combineReducers({
  user: userReducer,
  editor: editorReducer,
  toast: toastReducer,
  allUsers: allUsersReducer,
});

const reducerProxy = (state, action) => {
  if (action.type === "logout/LOGOUT") return appReducer(undefined, action);
  return appReducer(state, action);
};

const store = configureStore({
  reducer: reducerProxy,
});

export default store;
