import { Button, Grid, Typography } from "@mui/material";

const Page404 = () => (
  <Grid container height="100vh">
    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
      <Typography variant="h2">404</Typography>
      <Typography variant="h5">Page not found!</Typography>
      <Button variant="contained" color="primary" href="/" sx={{ mt: 2 }}>Go to Home</Button>
    </Grid>
  </Grid>
);

export default Page404;
