import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUser, loginUser } from "../../services/user";

const initialState = {
  user: {
    _id: "",
    email: "",
    fullName: "",
    role: "",
  },
  loading: false,
  loaded: false,
  error: "",
};

export const loadLoginUser = createAsyncThunk(
  "user/loadLoginUser",
  async (user, { rejectWithValue }) => {
    try {
      const { data, headers } = await loginUser(user);
      sessionStorage.setItem(
        "token",
        headers["authorization"].split(" ")[1]
      );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const loadGetUserInfo = createAsyncThunk(
  "user/loadGetUserInfo",
  async (payload, { rejectWithValue }) => {
    try {
      const { data, headers } = await getUser();
      sessionStorage.setItem(
        "token",
        headers["authorization"].split(" ")[1]
      );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const LOGIN_PENDING = loadLoginUser.pending;
const LOGIN_FULFILLED = loadLoginUser.fulfilled;
const LOGIN_REJECTED = loadLoginUser.rejected;
const GET_USER_INFO_PENDING = loadGetUserInfo.pending;
const GET_USER_INFO_FULFILLED = loadGetUserInfo.fulfilled;
const GET_USER_INFO_REJECTED = loadGetUserInfo.rejected;

const user = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(LOGIN_PENDING, (state) => {
      state.loading = true;
    });
    builder.addCase(LOGIN_FULFILLED, (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.user = payload;
      state.error = "";
    });
    builder.addCase(LOGIN_REJECTED, (state, { payload }) => {
      state.loading = false;
      state.loaded = false;
      // eslint-disable-next-line no-self-assign
      state.user = state.user;
      state.error = payload.message;
    });
    builder.addCase(GET_USER_INFO_PENDING, (state) => {
      state.loading = true;
    });
    builder.addCase(GET_USER_INFO_FULFILLED, (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.user = payload;
      state.error = "";
    });
    builder.addCase(GET_USER_INFO_REJECTED, (state, { payload }) => {
      state.loading = false;
      state.loaded = false;
      // eslint-disable-next-line no-self-assign
      state.user = state.user;
      state.error = payload.message;
    });
  },
});

export default user.reducer;
