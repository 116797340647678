import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import ProfileMenuList from './ProfileMenuList';

const styles = {
  navbar: {
    backgroundColor: 'white',
    position: 'fixed',
    minHeight: 60,
    zIndex: 100,
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: 3,
    pt: 1.5,
  },
  logo: {
    width: 36,
  },
};

const Header = () => {
  return (
    <Grid container sx={styles.navbar}>
      <Grid item xs={2}>
        <Link to="/">
          <img
            style={styles.logo}
            loading="lazy"
            src="https://topafrobeats.s3.amazonaws.com/assets/img/logo.webp"
            alt="logo"
          />
        </Link>
      </Grid>
      <Grid item xs={2} textAlign="right">
        <ProfileMenuList />
      </Grid>
    </Grid>
  );
};

export default Header;
