export function isReadyToSubmitPostTitle(title) {
  return title.length > 0;
}
export function isReadyToSubmitPostSlug(slug) {
  return slug.length > 0;
}

export function isValidEmail(email) {
  const emailRegex = /^(?:[\w+._-]{1,64}@[a-zA-Z0-9.-]{1,50}\s*,\s*)*[\w+._-]{1,64}@[\w.-]{1,50}\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};