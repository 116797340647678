import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  message: "",
  severity: "info",
};

const toast = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToast: (state, { payload }) => {
      state.open = payload.open;
      state.message = payload.message;
      state.severity = payload.severity;
    },
  },
});


export const { setToast } = toast.actions;
export default toast.reducer;
