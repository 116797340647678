import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  let { pathname } = useLocation();
  const { user } = useSelector((state) => state.user);

  if (user && user?._id) return <Component {...rest} />;
  return <Navigate to="/login" state={{ from: pathname }} replace />;
};

export default ProtectedRoute;
