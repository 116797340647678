import axios from "axios";

const httpBase = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "https://top-afrobeats-backend.vercel.app",
  },
});

httpBase.interceptors.request.use(
  function (config) {
    const token = sessionStorage.getItem("token");
    if (token) config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default httpBase;
